export const initialValues = {
  consultant_name: "",
  personal_details: {
    lead_source: "",
    title: "",
    first_name: "",
    surname: "",
    date_of_birth: "",
    email: "",
    mobile: "",
    landline: "",
    address_1: "",
    address_2: "",
    address_3: "",
    postcode: "",
    address_town: "",
    country: "",
    nationality: "",
    date_arrived: "",
    share_code: "",
    passport_number: "",
    passport_issue_date: "",
    passport_expiry_date: "",
    has_medical_conditions: "",
    medical_conditions_details: "",
    has_full_driving_licence: "",
  },
  method_of_transport: [],
  id_card: {
    entitled_to_work_in_uk: "",
    required_id: "",
    has_physical_or_mental_condition: "",
    conditions_details: "",
    has_been_convicted_or_criminal_offence: "",
  },
  equal_opportunities_monitoring: {
    has_disabilities: "",
    has_registered_disability: "",
    ethnic_origin: "",
    ethnic_origin_other: "",
  },
  criminal_record: {
    holds_a_criminal_records_bureau_disclosure: "",
    disclosure_details: "",
    convicted_of_offence_under_rehabilitation_act: "",
    under_act_offence_details: "",
    convicted_of_offence_not_under_rehabilitation_act: "",
    not_under_act_offence_details: "",
    has_security_clearance: "",
    security_clearance_granted: "",
    security_clearance_expires: "",
    confirm_information_accurate: 0,
    will_inform_of_changes: 0,
  },
  previous_employment: {
    previous_employer: "",
    temp_or_perm: "",
    with_agency: "",
    agency_name: "",
    number_of_temps: "",
    pay_rate: "",
    shift_pattern: "",
  },
  employment_history: [
    {
      company_name: "",
      job_title: "",
      date_from: "",
      date_to: "",
      reason_for_leaving: "",
      duties: "",
    },
  ],
  employer_contact: 0,
  references: [
    {
      name: "",
      company: "",
      job_title: "",
      contact: "",
      email: "",
      address: "",
    },
  ],
  privacy_notice: {
    understands: 0,
  },
  right_to_work_documents: [],
  cv: null,
  documents: [],
}
