import * as Yup from "yup"
import { dateRegex, phoneRegex, shareList, standardRequiredText } from "../../constants"
import { parseDateString, switchDateFormat } from "../../utils/form-data"
import moment from "moment"

const referenceFields = {
  name: Yup.string().required(standardRequiredText),
  company: Yup.string().required(standardRequiredText),
  job_title: Yup.string().required(standardRequiredText),
  contact: Yup.string()
    .matches(phoneRegex, "Must be a valid phone number")
    .required(standardRequiredText),
  email: Yup.string()
    .email("Must be a valid email address in the format 'example@example.com'")
    .required(standardRequiredText),
  address: Yup.string().required(standardRequiredText),
}

const companyFields = {
  company_name: Yup.string().required(standardRequiredText),
  job_title: Yup.string().required(standardRequiredText),
  date_from: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  date_to: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  reason_for_leaving: Yup.string(),
  duties: Yup.string(),
}

export const validationSchema = Yup.object().shape({
  consultant_name: Yup.string().required(standardRequiredText),
  personal_details: Yup.object().shape({
    lead_source: Yup.string().required(standardRequiredText),
    title: Yup.string().required(standardRequiredText),
    first_name: Yup.string().required(standardRequiredText),
    surname: Yup.string().required(standardRequiredText),
    date_of_birth: Yup.date()
      .transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      )
      .required(standardRequiredText),
    email: Yup.string()
      .email(
        "Must be a valid email address in the format 'example@example.com'"
      )
      .required(standardRequiredText),
    mobile: Yup.string()
      .matches(phoneRegex, "Must be a valid phone number")
      .required(standardRequiredText),
    landline: Yup.string().matches(phoneRegex, "Must be a valid phone number"),
    address_1: Yup.string().required(standardRequiredText),
    address_2: Yup.string(),
    address_3: Yup.string(),
    postcode: Yup.string().required(standardRequiredText),
    address_town: Yup.string().required(standardRequiredText),
    country: Yup.string().required(standardRequiredText),
    nationality: Yup.string().required(standardRequiredText),
    date_arrived: Yup.string().when('nationality', {
      is: (nationality) => nationality !== "United Kingdom",
      then: Yup.string().matches(
        dateRegex,
        "Must be a valid date in the format dd/mm/yyyy"
      ).required(standardRequiredText),
    }),
    share_code: Yup.string().when(['nationality', 'date_arrived'], {
      is:
        (nationality, date_arrived) => shareList.includes(nationality) && date_arrived && switchDateFormat(date_arrived) <= new Date("12/31/2020"),
      then: Yup.string().required(standardRequiredText).max(9, "Max 9 Characters"),
    }),
    passport_number: Yup.number().typeError("Must be a number"),
    passport_issue_date: Yup.string().matches(
      dateRegex,
      "Must be a valid date in the format dd/mm/yyyy"
    ),
    passport_expiry_date: Yup.string().matches(
      dateRegex,
      "Must be a valid date in the format dd/mm/yyyy"
    ),
    has_medical_conditions: Yup.string().required(standardRequiredText),
    medical_conditions_details: Yup.string(),
    has_full_driving_licence: Yup.string().required(standardRequiredText),
  }),
  method_of_transport: Yup.array()
    .min(1)
    .required(standardRequiredText),
  id_card: Yup.object().shape({
    entitled_to_work_in_uk: Yup.string().required(standardRequiredText),
    required_id: Yup.string(),
    has_physical_or_mental_condition: Yup.string().required(
      standardRequiredText
    ),
    conditions_details: Yup.string(),
    has_been_convicted_or_criminal_offence: Yup.string().required(
      standardRequiredText
    ),
  }),
  equal_opportunities_monitoring: Yup.object().shape({
    has_disabilities: Yup.string().required(standardRequiredText),
    has_registered_disability: Yup.string(),
    ethnic_origin: Yup.string().required(standardRequiredText),
    ethnic_origin_other: Yup.string(),
  }),
  criminal_record: Yup.object().shape({
    holds_a_criminal_records_bureau_disclosure: Yup.string().required(
      standardRequiredText
    ),
    disclosure_details: Yup.string(),
    convicted_of_offence_under_rehabilitation_act: Yup.string().required(
      standardRequiredText
    ),
    under_act_offence_details: Yup.string(),
    convicted_of_offence_not_under_rehabilitation_act: Yup.string().required(
      standardRequiredText
    ),
    not_under_act_offence_details: Yup.string(),
    has_security_clearance: Yup.string().required(standardRequiredText),
    security_clearance_granted: Yup.string(),
    security_clearance_expires: Yup.string(),
    confirm_information_accurate: Yup.number().oneOf([1], standardRequiredText),
    will_inform_of_changes: Yup.number().oneOf([1], standardRequiredText),
  }),
  previous_employment: Yup.object().shape({
    previous_employer: Yup.string().required(standardRequiredText),
    temp_or_perm: Yup.string().required(standardRequiredText),
    with_agency: Yup.string().required(standardRequiredText),
    agency_name: Yup.string(),
    number_of_temps: Yup.number()
      .integer("Must be valid, whole number")
      .required(standardRequiredText),
    pay_rate: Yup.number("Must be a number")
      .min(0, "Pay rate must be more than £0.00 per hour")
      .required(standardRequiredText),
    shift_pattern: Yup.string().required(standardRequiredText),
  }),
  employment_history: Yup.array()
    .of(Yup.object().shape(companyFields))
    .min(1),
  employer_contact: 0,
  references: Yup.array()
    .of(Yup.object().shape(referenceFields))
    .min(1),
  privacy_notice: Yup.object().shape({
    understands: Yup.number().oneOf([1], standardRequiredText),
  }),
  right_to_work_documents: Yup.array()
    .max(2, "Maximum of 2 documents")
    .required(standardRequiredText),
  cv: Yup.mixed()
    .nullable()
    .required(standardRequiredText),
  documents: Yup.array().max(2, "Maximum of 2 documents"),
})
