import React from "react"
import { Col2 } from "../../style/containers"
import SButton from "../../components/button"
import { FormikInput, FormikText } from "../form"
import { FormikCheckbox } from "../../components/form/checkbox"

const References = ({
  values,
  validateForm,
  setFieldTouched,
  setFieldValue,
  initialValues,
  colorTheme,
}) => {
  const addRow = async rep_type => {
    const errors = await validateForm()
    const currentReps = values[rep_type]

    const rep_obj = initialValues.employment_history[0]
    if (errors[rep_type]) {
      // touch all fields of last rep
      const fields = Object.keys(rep_obj)
      return fields.forEach(field => {
        const currentRep = currentReps.length - 1
        const fieldPath = `[${rep_type}][${currentRep}][${field}]`
        setFieldTouched(fieldPath)
      })
    } else {
      // add new rep
      setFieldValue(`[${rep_type}]`, [...currentReps, rep_obj])
    }
  }

  const removeRow = (rep_type, repIndexToRemove) => {
    const reps = values[rep_type]
    // filter out rep by index
    const updatedReps = reps.filter((_, idx) => idx !== repIndexToRemove)
    // clear any touched errors
    setFieldTouched(`[${rep_type}][${repIndexToRemove}]`, false)
    setFieldValue(`[${rep_type}]`, updatedReps)
  }

  return (
    <>
      <p>
        <strong>
          We require two professional references covering the last three years
          of your employment history as a minimum. At least one of the
          references must be your current or last employer. Please detail:
          contact name, contact number, full address and email address for each
          of your employers.
        </strong>
      </p>
      <p>
        If you have had less than two employers you should give a character
        reference who must have known you for at least three years and is not a
        relative.
      </p>
      <FormikCheckbox
        name="employer_contact"
        label="If you do not wish for your current employer to be contacted before induction please tick here (Please note we will require a reference from your most current employer before we can progress to induction)"
      />
      {values
        ? values.references.map((_, idx) => (
            <React.Fragment key={`references_${idx}`}>
              <h3>Reference - {idx + 1}</h3>
              <Col2>
                <FormikInput
                  name={`references[${idx}].name`}
                  type="text"
                  label="Name"
                />
                <FormikInput
                  name={`references[${idx}].company`}
                  type="text"
                  label="Company"
                />
                <FormikInput
                  name={`references[${idx}].job_title`}
                  type="text"
                  label="Job Title"
                />
                <FormikInput
                  name={`references[${idx}].contact`}
                  type="text"
                  label="Contact Number"
                />
                <FormikInput
                  name={`references[${idx}].email`}
                  type="email"
                  label="Email"
                />
              </Col2>
              <FormikText name={`references[${idx}].address`} label="Address" />
              {values.references.length > 1 && idx !== 0 ? (
                <SButton
                  type="button"
                  name="clear-signature"
                  onClick={() =>
                    removeRow(
                      "references",
                      idx,
                      values,
                      setFieldTouched,
                      setFieldValue
                    )
                  }
                  red={true}
                >
                  Delete reference
                </SButton>
              ) : null}
            </React.Fragment>
          ))
        : null}
      <hr />
      <SButton
        onClick={() =>
          addRow(
            "references",
            values,
            validateForm,
            setFieldTouched,
            setFieldValue
          )
        }
        colorTheme={colorTheme}
      >
        Add another references
      </SButton>
    </>
  )
}

export default References
