import React from "react"
import { FormikInput, FormikText, FormikSelect, FormikTabs, FormikDatepicker } from "../../form"
import { Col2 } from "../../../style/containers"
import { countryList } from "../../../constants"
import { TagSelector } from "../../form/tags"
import { dateOfBirthCheck } from "../../../services"

const PersonalDetails = ({ setFieldValue, values }) => {
  return (
    <>
      <Col2>
        <FormikSelect
          label="Where did you hear about the role?"
          name="personal_details.lead_source"
          options={[
            { value: "Facebook", label: "Facebook" },
            { value: "Indeed", label: "Indeed" },
            { value: "CV Library", label: "CV Library" },
            { value: "Friend", label: "Friend" },
            { value: "Other", label: "Other" },
          ]}
        />
        <FormikTabs
          setFieldValue={setFieldValue}
          name="personal_details.title"
          label="Title"
          tabs={[
            { name: "Mr", label: "Mr" },
            { name: "Mrs", label: "Mrs" },
            { name: "Miss", label: "Miss" },
            { name: "Ms", label: "Ms" },
          ]}
        />
        <FormikInput
          name="personal_details.first_name"
          type="text"
          label="First name"
        />
        <FormikInput
          name="personal_details.surname"
          type="text"
          label="Surname"
        />
        <FormikDatepicker
          name="personal_details.date_of_birth"
          label="Date of Birth"
          placeholderText="Select a date"
          maxDate={dateOfBirthCheck()}
        />
        <FormikInput name="personal_details.email" type="email" label="Email" />
        <FormikInput name="personal_details.mobile" type="tel" label="Mobile" />
        <FormikInput
          label="Landline"
          name="personal_details.landline"
          type="tel"
          optional={true}
        />
        <FormikInput
          type="text"
          label="Address line 1 - house number and street name"
          name="personal_details.address_1"
        />
        <FormikInput
          type="text"
          label="Address line 2"
          name="personal_details.address_2"
        />
        <FormikInput
          type="text"
          label="Town/City"
          name="personal_details.address_town"
        />
        <FormikInput
          type="text"
          label="County"
          name="personal_details.address_3"
        />
        <FormikInput
          name="personal_details.postcode"
          type="text"
          label="Postcode"
        />
        <FormikInput
          type="text"
          label="Country"
          name="personal_details.country"
        />
        <FormikTabs
          setFieldValue={setFieldValue}
          name="personal_details.has_full_driving_licence"
          label="Do you have a full UK driving licence?"
          tabs={[
            { name: "Yes", label: "Yes" },
            { name: "No", label: "No" },
          ]}
        />
        <TagSelector
          label="What are your current methods of transport?"
          name="method_of_transport"
          options={["Bike", "Bus", "Car", "Walk", "Other"]}
        />
      </Col2>
      <Col2>
        <h4>Nationality</h4>
        <FormikSelect
          label="Nationality"
          name="personal_details.nationality"
          options={countryList}
        />
      </Col2>
      <Col2>
        <FormikInput
          name="personal_details.date_arrived"
          type="text"
          label="UK Arrival Date"
          disabled={values?.personal_details?.nationality === "United Kingdom" || values?.personal_details?.nationality === ""}
        />
        <FormikInput
          name="personal_details.share_code"
          type="text"
          label="Share Code"
          disabled={values?.personal_details?.nationality === "United Kingdom" || values?.personal_details?.nationality === ""}
        />
        <FormikInput
          name="personal_details.passport_number"
          type="text"
          label="Passport Number"
        />
        <FormikInput
          name="personal_details.passport_issue_date"
          type="text"
          label="Passport Issued"
        />
        <FormikInput
          name="personal_details.passport_expiry_date"
          type="text"
          label="Passport Expiry"
        />
      </Col2>
      <h4>Medical Conditions</h4>
      <FormikTabs
        setFieldValue={setFieldValue}
        name="personal_details.has_medical_conditions"
        label="Do you have any medical conditions?"
        tabs={[
          { name: "Yes", label: "Yes" },
          { name: "No", label: "No" },
        ]}
      />
      {values.personal_details.has_medical_conditions === "Yes" ? (
        <FormikText
          label="Medical condition details"
          name="personal_details.medical_conditions_details"
        />
      ) : (
        ""
      )}
    </>
  )
}

export default PersonalDetails
